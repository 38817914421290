import React from 'react';
import './styles.scss';
import { ReactComponent as CarmenLogoWhite } from 'assets/images/carmen_white.svg'

import { useAppointmentsStore } from 'store/appointments';
import Stats from './Stats';

const Header = () => {
  const logo = useAppointmentsStore((store) => store.header.logoUrl);

  return (
    <header className="screensHeader">
      <div className="screensHeader__logo">
        <CarmenLogoWhite />

        {/*{ logo !== null ? <img src={`${logo}`} alt="logo" /> : <div className="screensHeader__preloader" /> }*/}
      </div>

      <Stats />
    </header>
  );
};

export default Header;
