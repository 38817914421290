import React from 'react';
import { useTransition } from 'react-spring';

import StatItem from '../StatItem';


const HEADER_STATS = [
  {
    label: 'Today',
    storeKey: 'today',
  },
  {
    label: 'Not yet arrived',
    storeKey: 'notYetArrived',
  },
  {
    label: 'On Site',
    storeKey: 'onSite',
  },
  {
    label: 'Waiting',
    storeKey: 'waiting',
  },
];

const Stats = () => {
  const transitions = useTransition(HEADER_STATS, (stat) => stat.storeKey, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 200,
    config: { mass: 1, tension: 100, friction: 30 },
  });

  return <div className="stats">{ transitions.map(({ item, props, key }) => <StatItem key={key} style={props} {...item} />) }</div>;
};

export default Stats;
