import { useEffect } from 'react';
import ActionCable from 'actioncable';

import { useAppointmentsStore } from 'store/appointments';

const TvCable = (props) => {
  const setStore = useAppointmentsStore((store) => store.set);
  const storeCable = useAppointmentsStore((store) => store.cable);
  const storeSubscription = useAppointmentsStore((store) => store.subscription);
  const channel_key = useAppointmentsStore((store) => store.auth.channel_key);

  useEffect(() => {
    // ActionCable.debugging = true;
    const cable = ActionCable.createConsumer(props.cableUrl);
    cable.ensureActiveConnection();

    cable.connection.events.error = () => {
      setStore((store) => {
        /* eslint-disable no-param-reassign */
        store.isConnected = false;
        store.willAttemptReconnect = true;
        /* eslint-enable */
      });
    };

    if (storeSubscription) {
      storeCable.subscriptions.remove(storeSubscription);
    }

    const subscription = cable.subscriptions.create({
      channel_key,
    }, {
      connected: () => {
        setStore((store) => {
          /* eslint-disable no-param-reassign */
          store.isConnected = true;
          /* eslint-enable */
        });
      },

      disconnected: ({ willAttemptReconnect }) => {
        setStore((store) => {
          /* eslint-disable no-param-reassign */
          store.isConnected = false;
          store.willAttemptReconnect = willAttemptReconnect;
          /* eslint-enable */
        });
      },
      received: (msg) => {
        props.receiveHandler(msg, setStore);
      },
    });


    setStore((store) => {
      /* eslint-disable no-param-reassign */
      store.cable = cable;
      store.subscription = subscription;
      /* eslint-enable */
    });

    return () => {
      storeCable.subscriptions.remove(storeSubscription);
      setStore((store) => {
        /* eslint-disable no-param-reassign */
        store.cable = null;
        store.subscription = null;
        store.isConnected = null;
        store.willAttemptReconnect = null;
        /* eslint-enable */
      });
    };
  }, []); // eslint-disable-line

  return null;
};

export default TvCable;
