import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import shallow from 'zustand/shallow';

import { vmax } from 'utils/sizing';
import { useAppointmentsStore } from 'store/appointments';

import AppointmentItem from '../AppointmentItem';

const List = () => {
  const itemsPerPage = useAppointmentsStore((store) => store.itemsPerPage);
  const page = useAppointmentsStore((store) => store.page);
  const appointmentsKeys = useAppointmentsStore((store) => store.dataKeys, shallow);
  const offset = Math.ceil(page * itemsPerPage);
  return Array(itemsPerPage).fill(null).map((_, i) => {
    const idx = i + offset;
    if (appointmentsKeys[idx] === undefined) return null;
    return <AppointmentItem key={appointmentsKeys[idx]} itemId={appointmentsKeys[idx]} />;
  });
};

const AppointmentsList = (props) => {
  const setStore = useAppointmentsStore((store) => store.set);

  const pollItems = useAppointmentsStore((store) => store.pollItems);

  const calcItems = () => {
    if (props.containerHeight > 0) {
      const itemHeightPct = window.matchMedia('(orientation: landscape)').matches ? 0.054166 : 0.071875;

      const itemHeight = itemHeightPct * vmax(100);

      setStore((store) => {
        /* eslint-disable no-param-reassign */
        store.itemsPerPage = Math.floor(props.containerHeight / itemHeight) - 2;
        /* eslint-disable */
      });
    }
  };

  useEffect(() => {
    pollItems();
  }, []);

  useEffect(() => {
    calcItems();
    window.addEventListener('resize', calcItems);

    return () => {
      window.removeEventListener('resize', calcItems);
    };
  }, [props.containerHeight]); // eslint-disable-line

  // console.log(appointmentsKeys, items)

  return <List />;
};

AppointmentsList.propTypes = {
  containerHeight: PropTypes.number.isRequired,
};

export default AppointmentsList;
