import 'normalize.css';
import './styles/index.scss';

import './polyfills';

import React from 'react';
import { render } from 'react-dom';
import TvApp from 'components/TvApp';

import dotenv from 'dotenv';

dotenv.config();

const config = {
  theme: 'light',
  cableUrl: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CABLE_PATH}`,
  showConnectionError: false,
};


render(
  <TvApp {...config} />,
  document.getElementById('root'),
);
