import React from 'react';
import ReactPaginate from 'react-paginate';

import { useAppointmentsStore } from 'store/appointments';

const Pagination = () => {
  const pages = useAppointmentsStore((store) => store.pages);
  const itemsPerPage = useAppointmentsStore((store) => store.itemsPerPage);
  const setStore = useAppointmentsStore((store) => store.set);

  if (itemsPerPage === null || pages === null) {
    return null;
  }

  return (
    <ReactPaginate
      containerClassName="screensFooter__pagination"
      previousLabel={false}
      nextLabel={false}
      pageCount={pages}
      initialPage={0}
      onPageChange={(data) => {
        setStore((store) => {
          /* eslint-disable no-param-reassign */
          store.page = data.selected;
          /* eslint-disable */
        });
      }}
    />
  )
};

export default Pagination
