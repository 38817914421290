import React from 'react';
import './styles.scss';
import auth from 'utils/auth';

import { useAppointmentsStore } from 'store/appointments';

import { ReactComponent as CarmenLogo } from 'assets/images/carmen.svg';

import Pagination from './Pagination';

const Footer = () => {
  const theme = useAppointmentsStore((store) => store.theme);

  const logout = () => {
    auth.clear();
    window.location.reload();
  }

  return (
    <footer className={`screensFooter screensFooter--${theme}`}>
      <CarmenLogo className="screensFooter__logo" onClick={logout} />
      <a className="screensFooter__link" href="https://getcarmen.com">GetCarmen.com</a>

      <Pagination />
    </footer>
  );
};

export default Footer;
