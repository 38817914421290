import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import propTypes from 'prop-types';

import './styles.scss';

import { useAppointmentsStore } from 'store/appointments';

function Icon() {
  return (
    <svg width="52" height="12" viewBox="0 0 52 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="white">
        <animate
          attributeName="opacity"
          dur="2s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </path>
      <path d="M26 12C29.3137 12 32 9.31371 32 6C32 2.68629 29.3137 0 26 0C22.6863 0 20 2.68629 20 6C20 9.31371 22.6863 12 26 12Z" fill="white">
        <animate
          attributeName="opacity"
          dur="2s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </path>
      <path d="M46 12C49.3137 12 52 9.31371 52 6C52 2.68629 49.3137 0 46 0C42.6863 0 40 2.68629 40 6C40 9.31371 42.6863 12 46 12Z" fill="white">
        <animate
          attributeName="opacity"
          dur="2s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </path>
    </svg>
  );
}

const StatItem = (props) => {
  const value = useAppointmentsStore((store) => store.header[props.storeKey]);

  const [spring, setSpring] = useSpring(() => ({
    value: value === null ? 0 : value,
    config: {
      mass: 1, tension: 120, friction: 20, clamp: true,
    },
  }));

  useEffect(() => {
    if (value !== null) {
      setSpring({ value });
    }
  }, [value, setSpring]);

  if (value === null) {
    return <div className="stats__item stats__item--loading"><div /></div>;
  }

  return (
    <animated.div
      className="stats__item"
      style={props.style}
    >
      <span className="stats__label">
        {props.label}:
      </span>

      <animated.span className="stats__value">
        { value === null ? <Icon /> : spring.value.interpolate((v) => Math.floor(v)) }
      </animated.span>
    </animated.div>
  );
};

StatItem.propTypes = {
  storeKey: propTypes.string,
  label: propTypes.string,
  style: propTypes.object, // eslint-disable-line react/forbid-prop-types
};

StatItem.defaultProps = {
  storeKey: null,
  label: null,
  style: null,
};

export default StatItem;
