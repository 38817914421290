const actionCableReceive = (message, setStore) => {
  const { action, data, errors } = message;
  if (errors) {
    console.log(errors);
  }
  switch (action) {


    default:
      console.log(action, data);
      try {
        const keys = data.map((item) => item.guid);
        setStore((store) => {
          /* eslint-disable no-param-reassign */
          store.dataKeys = keys;
          data.forEach((item) => {
            store.data[item.guid] = item;
          });

          store.pages = Math.ceil(keys.length / store.itemsPerPage);
          /* eslint-disable */
        })
      } catch (error) {
        console.log('ws error', error);
      }

      break;
  }
};
  
export { actionCableReceive };
  