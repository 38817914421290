import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useAppointmentsStore } from 'store/appointments';
import { actionCableReceive } from 'shared/actioncable-receive';
import { vmax, BODY_FONTSIZE_PERCENT } from 'utils/sizing';

import TvSignin from 'components/TvSignin';
import TvCable from 'components/common/TvCable';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Appointments from 'components/Appointments';

const TvApp = (props) => {
  const auth_token = useAppointmentsStore((store) => store.auth.access_token);

  const sanityFontSize = () => {
    const windowSize = vmax(100);
    const currentFsPx = Math.round(parseFloat(getComputedStyle(document.body).fontSize));
    const currentFsPercent = (currentFsPx * 100) / windowSize;

    if (currentFsPercent > (BODY_FONTSIZE_PERCENT * 1.5)
      || currentFsPercent < (BODY_FONTSIZE_PERCENT / 1.5)) {
      document.body.style.fontSize = `${Math.round((BODY_FONTSIZE_PERCENT * windowSize) / 100)}px`;
    }
  };

  useLayoutEffect(() => {
    sanityFontSize();
    window.addEventListener('resize', sanityFontSize);

    return () => {
      window.removeEventListener('resize', sanityFontSize);
    };
  }, []);

  return (
    <div className="screensApp">
      <TvSignin />

      { auth_token !== undefined && (
        <>
          <TvCable
            receiveHandler={actionCableReceive}
            cableUrl={`${props.cableUrl}?access_token=${auth_token}`}
          />
          <Header />
          <Appointments />
          <Footer />
        </>
      ) }



    </div>
  );
};

TvApp.propTypes = {
  cableUrl: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

TvApp.defaultProps = {
  cableUrl: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CABLE_PATH}`,
  theme: 'light',
};

export default TvApp;
