import cookies from 'js-cookie'

const TOKEN_KEY = 'access_token'

const auth = {

    setToken(token) {
        cookies.set(TOKEN_KEY, token, { espires: 60 });
    },
    getToken() {
        return cookies.get(TOKEN_KEY);
    },

    set(auth_response) {
        for (const key in auth_response) {
            cookies.set(key, auth_response[key], {expires: 60})
        }
    },

    // set: cookies.set ,
    get: cookies.get,
    remove: cookies.remove,

    clear() {
        cookies.remove('access_token');
        cookies.remove('refresh_token');
        cookies.remove('channel_key');
        cookies.remove('expires_in');
        cookies.remove('created_at');
        cookies.remove('token_type');
        cookies.remove('dealershipId');
        cookies.remove('role');
    }
}

export default auth
