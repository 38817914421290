import './styles.scss';
import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';

import { ReactComponent as CarmenLogoBlack } from 'assets/images/carmen.svg';

import { useAppointmentsStore } from "store/appointments";

const TvSignin = () => {
  const loading = useAppointmentsStore((store) => store.auth.loading);
  const authorize = useAppointmentsStore((store) => store.auth.authorize);
  const auth_token = useAppointmentsStore((store) => store.auth.access_token);
  const authError = useAppointmentsStore((store) => store.auth.error);

  const [creds, setCredentials] = useState({
    username: '',
    password: '',
  });

  const onUsernameChange = (ev) => setCredentials({...creds, username: ev.target.value});
  const onPasswordChange = (ev) => setCredentials({...creds, password: ev.target.value});

  const hndSubmit = (ev) => {
    ev.preventDefault();
    authorize(creds.username, creds.password);
  };

  const transitions = useTransition( auth_token === undefined, null, {
    from: { opacity: 0 , transform: 'translate( -50%, -30% )' },
    enter: { opacity: 1 , transform: 'translate( -50%, -50% )' },
    leave: { opacity: 0 , transform: 'translate( -50%, -80% )' },
    config: { mass: 1, tension: 182, friction: 26, clamp: true },
  });

  return transitions.map(({item, key, props}) =>
    item && <animated.div key={key} style={props} className="screensSignin" >
      <div className="screensSignin__head" >
        <CarmenLogoBlack />
      </div>

      <div className="screensSignin__content" >
        <form onSubmit={hndSubmit} >
          <div className="screensSignin__inputWrap" >
            <p className="screensSignin__inputLabel" >Username:</p>
            <input
              className="screensSignin__input"
              onChange={onUsernameChange}
              value={creds.username}
            />
          </div>

          <div className="screensSignin__inputWrap" >
            <p className="screensSignin__inputLabel" >Password:</p>
            <input
              className="screensSignin__input"
              type="password"
              onChange={onPasswordChange}
              value={creds.password}
            />
          </div>

          <button
            type="submit"
            className="screensSignin__button"
            disabled={(creds.username.length === 0 || creds.password.length === 0) || loading}
          >
              Log in
          </button>

          { authError === 401 && (
            <div className="screensSignin__errors" >
              <p>Authentication failed. </p>
              <p>Check if email and password are correct</p>
            </div>
          ) }

        </form>
      </div>
    </animated.div>
  )
};

export default TvSignin;
