import create from 'zustand';
import produce, { setAutoFreeze } from 'immer';

import { getAuthToken, fetchItems, refreshAuth } from 'shared/tv-api';
import auth from 'utils/auth';

setAutoFreeze(false);

const authorize = (setStore, getStore) => async (username, password) => {
  setStore((buf) => {
    buf.auth.loading = true;
  })
  const store = getStore();
  let resp;
  try {
    resp = await getAuthToken(username, password);
    resp = resp.data
    
    auth.clear();
    auth.set(resp);

    setStore(produce((bufStore) => {
      /* eslint-disable no-param-reassign */
      bufStore.auth.access_token = auth.getToken();
      /* eslint-disable */
    }));
  } catch (error) {
    setStore(produce((bufStore) => {
      /* eslint-disable no-param-reassign */
      bufStore.auth.loading = false;
      bufStore.auth.error = error.response.status
      /* eslint-disable */
    }));
  }
};

const authorizeRefresh = (setStore, getStore) => async (refresh_token) => {
  setStore((buf) => {
    buf.auth.loading = true;
  })
  const store = getStore();
  let resp;
  try {
    resp = await refreshAuth(refresh_token);
    resp = resp.data
    
    auth.clear();
    auth.set(resp);

    setStore(produce((bufStore) => {
      /* eslint-disable no-param-reassign */
      bufStore.auth.access_token = auth.getToken();
      /* eslint-disable */
    }));
  } catch (error) {
    auth.clear();
    setStore(produce((bufStore) => {
      /* eslint-disable no-param-reassign */
      bufStore.auth.loading = false;
      bufStore.auth.error = error.response.status;
      bufStore.auth.access_token = undefined;
      /* eslint-disable */
    }));
  }
}

const pollItems = (setStore, getStore) => async () => {
  const store = getStore();

  let data;
  try {
    data = await fetchItems(auth.get());
    // const keys = data.map((item) => item.guid);

    // rm after duplicates fixed
    const distinct_keys = (() => {
      let buf = {};
      return data.filter((item) => buf[item.guid] !== undefined ? false : buf[item.guid] = true )
        .map((item) => item.guid)
    })();

    // sort by utime
    // rm after api fixed
    const keys = distinct_keys.map(k => ({
      key: k,
      value: Date.parse(data.find(item => item.guid === k).appointment_datetime)
    })).sort((x,y) => x.value - y.value).map(el => el.key);


    setStore((bufStore) => {
      /* eslint-disable no-param-reassign */

      bufStore.dataKeys = keys

      // probably FIXME if duplicates persist
      data.forEach((item) => {
        bufStore.data[item.guid] = item;
      });

      if ( store.itemsPerPage ) {
        bufStore.pages = Math.ceil(keys.length / store.itemsPerPage)
      }
      console.log(bufStore.data)
      bufStore.header = {
        ...bufStore.header,

        ...Object.keys(bufStore.data).reduce( (acc, next) => {
          if ( bufStore.data[next].client_waiting === true ) {
            acc.waiting += 1
          }
          if ( bufStore.data[next].arrived_at === null ) {
            acc.notYetArrived += 1
          }
          if ( bufStore.data[next].aasm_state === 'arrived' ) {
            acc.onSite += 1
          }
          return acc;
        }, {
          notYetArrived: 0,
          onSite: 0,
          waiting: 0,
        } ) ,

        today: bufStore.dataKeys.length
      };
      /* eslint-disable*/
    })
  } catch (error) {
    console.error('PollItems error ', error);
    const refresh_token = auth.get('refresh_token');
    if (refresh_token) {
      store.auth.authorizeRefresh(refresh_token);
    }
  } finally {
    await new Promise((resolve) => setTimeout(resolve, 10000));
    await store.pollItems();
  }
}

const [useAppointmentsStore] = create((set, get) => ({
  set: (cb) => set(produce(cb)),

  auth: {
    access_token: auth.getToken(),
    token_type: null,
    channel_key: null,
    dealershipId: null,
    expires_in: 0,
    created_at: 0,

    loading: false,
    error: null,
    authorize: authorize(set, get),
    authorizeRefresh: authorizeRefresh(set, get),
  },

  isConnected: null,
  willAttemptReconnect: null,

  theme: 'light',

  header: {
    logoUrl: '/audi.png',

    today: null,
    notYetArrived: null,
    onSite: null,
    waiting: null,
    collected: null,
  },

  pages: 0,
  page: 1,

  itemsPerPage: null,

  data: {},
  dataKeys: [],

  cable: null,
  subscription: null,

  polling_active: true,

  pollItems: pollItems(set, get)

}));

export { useAppointmentsStore };
