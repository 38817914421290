import React, { useState, useCallback } from 'react';
import './styles.scss';

import { useAppointmentsStore } from 'store/appointments';
import AppointmentsList from './AppointmentsList';

const Appointments = () => {
  const [containerHeight, setContainerHeight] = useState(0);
  const containerHeightRef = useCallback((el) => {
    if (el !== null) {
      setContainerHeight(el.offsetHeight);
    }
  }, []);

  const theme = useAppointmentsStore((store) => store.theme);

  return (
    <section
      ref={containerHeightRef}
      className={`page page--${theme}`}
    >
      <div className="appointments">

        <div className="appointments__header">
          <div className="appointments__timeHeader">
            <p>Reservation time</p>
            <div className="ambient-grey">Arrived</div>
          </div>
          <div className="appointments__customerHeader">
            <p>
              Customer
            </p>
          </div>
          <div className="appointments__advisorHeader">
            <p>
              Advisor
            </p>
          </div>
          <div className="appointments__statusHeader">
            <p>
              Status
            </p>
          </div>
        </div>

        <AppointmentsList
          containerHeight={containerHeight}
        />
      </div>
    </section>
  );
};

export default Appointments;
