import axios from 'axios';

const AUTH_TOKEN_PATH = '/oauth/token';
const DEALERSHIPS_PATH = '/dealerships';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const dealershipTvItemsPath = (dealershipId) => `${DEALERSHIPS_PATH}/${dealershipId}/tv`;

export const getAuthToken = async (username, password) => {
  return axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      url: AUTH_TOKEN_PATH,
      data: {
        username,
        password,
        grant_type: 'password',
      },
    })
};

export const refreshAuth = async (refresh_token) => {
  return axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: AUTH_TOKEN_PATH,
    data: {
      refresh_token,
      grant_type: 'refresh_token'
    }
  })
}

export const fetchItems = async (authData) => {
  let response;
  try {
    response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: dealershipTvItemsPath(authData.dealershipId),
      headers: { authorization: `${authData.token_type} ${authData.access_token}` },
    });
  } catch (error) {
    console.error('API fetch error: ', error);
  }
  return response.data;
};
