import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as CoffeeIcon } from 'assets/images/coffee-icon.svg';
import { ReactComponent as MaintenanceIcon } from 'assets/images/maintenance.svg';
import { useAppointmentsStore } from 'store/appointments';

const lpad = (d) => (`0${d}`).slice(-2);

const getTime = (dateStr) => {
  const date = new Date(dateStr);
  return `${lpad(date.getHours())}:${lpad(date.getMinutes())}`;
};

const vehicleName = (vehicle) => `${vehicle.year} ${vehicle.make} ${vehicle.model}`;

const itemStatus = (item) => {
  let str = "Not yet arrived";
  if (item.aasm_state === 'open' && item.arrived_at === null) {
    str = "Not yet arrived";
  }
  if (item.aasm_state === 'arrived') {
    str =  "On Site";
  }
  return str;
}

const AppointmentItem = ((props) => {
  const item = useAppointmentsStore((store) => store.data[props.itemId]);

  if (item === undefined) {
    return null;
  }

  return (
    <div className="appointments__row">
      <div
        className={classNames('appointments__item', { 'appointments__item--checkingIn': item.check_in })}
      >
        <div className="appointments__time">
          <div className="appointments__timeScheduled">
            {/* FIXME date timezone */}
            <p>{getTime(item.appointment_datetime + ' Z')}</p>
          </div>
          <div className="ambient-grey">
            {item.arrived_at === null ? '—' : getTime(item.arrived_at)}
          </div>
        </div>

        <div className="appointments__customer">
          <div className="appointments__car">
            { item.vehicle.image !== null
              && (
                <img
                  className="appointments__carImage"
                  alt="car_image"
                  src={item.vehicle.image}
                />
              )
            }
            {/* <div className="appointments__carImage" style={{ backgroundImage: `url(${item.vehicle.image})` }} /> */}
          </div>
          <div className="appointments__customerText">
            <p className="appointments__customerName">
              {item.customer_name}
            </p>
            <p className="ambient-grey appointments__carName">
              {vehicleName(item.vehicle)}
            </p>
          </div>
        </div>

        <div className="appointments__advisor">
          { item.service_advisor.image !== null
            && item.service_advisor.image.url !== null
              && (
                <img
                  className="appointments__advisorImage"
                  src={item.service_advisor.image.url}
                  alt="advisor"
                />
              )
          }
          {/* <div className="appointments__advisorImage" style={{ backgroundImage: `url(${item.service_advisor.image})` }} /> */}
          <div className="appointments__advisorName">
            {item.service_advisor.name}
          </div>
        </div>

        <div className="appointments__status">
          <p className="appointments__statusText">
            {itemStatus(item)}
          </p>

          <ul className="appointments__icons">
            <li className={classNames('appointments__check', {
              'appointments__check--checkedIn': item.aasm_state === 'arrived',
              'appointments__check--notArrived': item.arrived_at === null && item.check_in === 'online',
              'appointments__check--progressKiosk': item.check_in === 'kiosk',
            })}
            >
              <CheckIcon />
            </li>
            <li className={classNames('appointments__icon', {
              'appointments__icon--show': item.client_waiting,
            })}
            >
              <CoffeeIcon />
            </li>
            <li className={classNames('appointments__icon', {
              'appointments__icon--show': item.with_concern,
            })}
            >
              <MaintenanceIcon />
            </li>
          </ul>
        </div>


      </div>
    </div>
  );
});

AppointmentItem.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default AppointmentItem;
